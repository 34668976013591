<template>
    <CModal
      title="Firmar Contrato y Carta Responsiva"
      :color="color"
      :show.sync="signModal"
    >   
        <CRow>
          <CCol style="min-height: 200px; text-align: center; vertical-align: middle;">
            <canvas id="canvas" style="border: 1px solid; width: 100%;"></canvas>
          </CCol>
        </CRow>
        <template #footer>
          <CButton @click="clearSign" color="secondary" style="float: right;">Limpiar</CButton>
          <CButton @click="signEnrollment" :color="color" style="float: right;">Firmar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
  name: 'SignModal',
  props: {

  },
  data () {
    return {
      signModal: false,
      title: "Firmar Contrato y Carta Responsiva",
      color: "info",
      signer: {
        haComenzadoDibujo: false,
        contexto: false,
        COLOR_PINCEL: "black",
        COLOR_FONDO: "white",
        GROSOR: 5,
        xAnterior: 0,
        yAnterior: 0,
        xActual: 0,
        yActual: 0,
        canvas: false
      },
      has_sign: false
    }
  },
  mounted: async function() {
    this.signer.canvas = await document.getElementById("canvas");
    this.signer.contexto = await this.signer.canvas.getContext("2d");

    const obtenerXReal = (clientX) => clientX - this.signer.canvas.getBoundingClientRect().left;
    const obtenerYReal = (clientY) => clientY - this.signer.canvas.getBoundingClientRect().top;

    const clickMouse = evento => {
        // En este evento solo se ha iniciado el clic, así que dibujamos un punto
        this.signer.xAnterior = this.signer.xActual;
        this.signer.yAnterior = this.signer.yActual;
        this.signer.xActual = obtenerXReal(evento.clientX);
        this.signer.yActual = obtenerYReal(evento.clientY);
        this.signer.contexto.beginPath();
        this.signer.contexto.fillStyle = this.signer.COLOR_PINCEL;
        this.signer.contexto.fillRect(this.signer.xActual, this.signer.yActual, this.signer.GROSOR, this.signer.GROSOR);
        this.signer.contexto.closePath();
        // Y establecemos la bandera
        this.signer.haComenzadoDibujo = true;
    }

    const moverMouse = evento => {
        evento.preventDefault(); // Prevenir scroll en móviles
        if (!this.signer.haComenzadoDibujo) {
            return;
        }
        // El mouse se está moviendo y el usuario está presionando el botón, así que dibujamos todo
        let target = evento;
        if (evento.type.includes("touch")) {
            target = evento.touches[0];
        }
        this.signer.xAnterior = this.signer.xActual;
        this.signer.yAnterior = this.signer.yActual;
        this.signer.xActual = obtenerXReal(target.clientX);
        this.signer.yActual = obtenerYReal(target.clientY);
        this.signer.contexto.beginPath();
        this.signer.contexto.moveTo(this.signer.xAnterior, this.signer.yAnterior);
        this.signer.contexto.lineTo(this.signer.xActual, this.signer.yActual);
        this.signer.contexto.strokeStyle = this.signer.COLOR_PINCEL;
        this.signer.contexto.lineWidth = this.signer.GROSOR;
        this.signer.contexto.stroke();
        this.signer.contexto.closePath();

        this.has_sign = true;
    }

    const soltarMouse = () => {
        this.signer.haComenzadoDibujo = false;
    };

    let $btnLimpiar = await document.getElementById("canvas");

    ["mousedown", "touchstart"].forEach(nombreDeEvento => {
        this.signer.canvas.addEventListener(nombreDeEvento, clickMouse);
    });

    ["mousemove", "touchmove"].forEach(nombreDeEvento => {
        this.signer.canvas.addEventListener(nombreDeEvento, moverMouse);
    });

    ["mouseup", "touchend"].forEach(nombreDeEvento => {
        this.signer.canvas.addEventListener(nombreDeEvento, soltarMouse);
    });
  },
  methods: {
    openModal () {
      this.signModal = true;
      this.color = "info";
      this.title = 'Firmar';
      
    },
    clearSign () {
      this.signer.contexto.fillStyle = this.signer.COLOR_FONDO;
      this.signer.contexto.fillRect(0, 0, this.signer.canvas.width, this.signer.canvas.height);

      this.has_sign = false;
    },
    signEnrollment () {
      let sign = this.signer.canvas.toDataURL();

      if(this.has_sign){
        this.closeModal();
      
        this.$emit("sign", sign);
      }
      else{
        alert("Para poder completar el registro es necesario agregar tu firma.");
      }
    },
    closeModal () {
      this.signModal = false;
    },
  }
}
</script>