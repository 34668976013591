<template>
  <div class="c-app">
    <CWrapper>
      <div class="c-body" style="height: 100%; overflow: hidden;">
        <main class="c-main" style="padding: 0px;">
          <div v-if="enrollment_id > 0">
            <CRow>
              <CCol md="12" :style='header_type'></CCol>
            </CRow>
            <CRow>
              <CCol md="2" style="text-align: left;"></CCol>
              <CCol md="8" style="text-align: left;">
                <div style="background-color: white; position: relative; top: -50px; padding: 30px; height: 600px; font-size: 16px; font-family: 'Open Sans'; ">
                  <h2>¡¡BIENVENIDO!!</h2>
                  <br />
                  <p style="text-align: justify; text-justify: inter-word;">Tu registro ha sido completado de manera exitosa, en unos momentos recibirás un correo electrónico con tu contraseña, con la cual podrás acceder a nuestra plataforma de información.</p>
                  <p v-if="travel.whatsapp_group_link != undefined && travel.whatsapp_group_link != ''" style="text-align: justify; text-justify: inter-word;">Te invitamos a registrarte en el grupo de <b>Whatsapp</b> del viaje <b><a :href="travel.whatsapp_group_link" target="_blank">{{travel.whatsapp_group_link}}</a></b></p>
                  <p style="text-align: justify; text-justify: inter-word;">Cualquier duda, les pedimos que nos contacten por celular o whatsapp al <b>{{travel.contact_phone}}</b> con <b>{{travel.contact_name}}</b>.</p>
                  <p style="text-align: justify; text-justify: inter-word;">Descarga tu contrato y carta responsiva:</p>
                  <ul>
                    <li><a :href="enrollment.contract_filename" target="_blank">Contrato</a></li>
                    <li><a :href="enrollment.responsive_filename" target="_blank">Carta Responsiva</a></li>
                  </ul>
                  <br /><br />
                  <img src="main_logo.png" style="width: 100px; float: right;">
                </div>
              </CCol>
              <CCol md="2" style="text-align: left;"></CCol>
            </CRow>
          </div>
          <CForm v-if="enrollment_id == 0" id="enrollment_form" method="POST" enctype="multipart/form-data">
            <CContainer fluid style="padding: 0px;">
              <CRow>
                <CCol md="12" :style='header_type'>

                </CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="background-color: white; position: relative; top: -50px; padding: 30px; font-size: 14px; font-family: 'Open Sans'; ">
                    <img src="main_logo.png" style="width: 200px;">
                    <br /><br /><br />
                    <h2><b>{{travel.name}}</b></h2>
                    <br />
                    Estimados papás: Este formulario nos servirá para tener toda la información de los alumnos, cualquier duda les pedimos que nos contacten por celular o WhatsApp al {{travel.contact_phone}} con {{travel.contact_name}}.
                    <br /><br />
                    <div style="width: 100%; font-weight: bold;">EL FORMULARIO DEBE SER LLENADO POR LOS PADRES DE FAMILIA</div>
                    <br />
                    <b>Fecha del Viaje: </b>{{travel.dates}}
                    <br />
                    <br />
                    <b>Presentación del viaje: </b>
                    <br />
                    <span v-if="!travel.presentation">Por definir</span><a :href="travel.presentation" target="_blank" v-if="travel.presentation" style="font-size: 12px;">Ver Presentación</a>
                    <br />
                    <br />
                    <b>Seguro de viaje: </b>
                    <br />
                    <span v-if="!travel.insurance">Por definir</span>
                    <span v-if="travel.insurance == 'colegio'">Los alumnos viajarán con el seguro del colegio.</span>
                    <a :href="travel.insurance" target="_blank" v-if="travel.insurance && travel.insurance != 'colegio'" style="font-size: 12px;">Ver Seguro</a>
                    <br />
                    <br />
                    <pre style='width: 100%; font-size: 16px; font-family: "Open Sans"; white-space: pre-wrap; font-size: 14px;'><div v-html="travel.itinerary1"></div></pre>
                    <span v-if="travel.payments && travel.payments.length > 0">
                      Fechas de pago:
                      <table style="border: 0px solid; width: 100%;">
                        <tr v-for="payment in travel.payments">
                          <td>{{payment.name}}</td>
                          <td style="width: 20px;"></td>
                          <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount > 100 && payment.amount != 99999 && payment.amount != 88888">{{travel.type != 'national' ? 'USD' : ''}} ${{payment.amount | icurrency}} {{travel.type != 'national' ? '' : 'Pesos'}}</td>
                          <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount > 0 && payment.amount <= 100">{{parseInt(payment.amount)}}%</td>
                          <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount == 99999">PENDIENTE</td>
                          <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount == 88888">RESTO</td>
                        </tr>
                      </table>
                      <br />
                      <br />
                    </span>
                    <pre style='width: 100%; font-size: 16px; font-family: "Open Sans"; white-space: pre-wrap; font-size: 14px;'><div v-html="travel.itinerary2"></div></pre>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <!-- Preguntas Genéricas -->
              <CRow v-for="question in travel.form_questions" :id="question.code" :ref="question.code" v-if="
                  question.code != 'has_second_nationality' || (question.code == 'has_second_nationality' && travel.type != 'national')
                  ||
                  question.code != 'second_nationality' || (question.code == 'second_nationality' && travel.type != 'national')
                ">
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -50px; padding: 30px 30px 0 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <span v-if="(question.code == 'curp' && nationality == 'México') || (question.code == 'second_nationality' && second_nationality) || (question.code != 'curp' && question.code != 'second_nationality')">
                      <b>{{question.question}}</b> <span v-if="question.required" style="color: red">*</span>
                      <br />
                      <span style="font-size: 14px;" v-if="question.description">{{question.description}}</span>
                      <br v-if="question.description" />
                      <br />
                    </span>

                    <select v-if="question.type == 'text' && question.code == 'nationality'" v-model="question.answer" @change="changeNationality($event, question)" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                      <option v-for="option in nationalities" :value="option.es_name" :selected="option.es_name == 'México'">{{option.es_name}}</option>
                    </select>

                    <select v-if="question.type == 'text' && question.code == 'second_nationality' && second_nationality" v-model="question.answer" @change="changeNationality($event, question)" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                      <option v-for="option in nationalities" :value="option.es_name" :selected="option.es_name == 'México'">{{option.es_name}}</option>
                    </select>
                    <!-- Text -->

                    <input v-if="question.type == 'text' && question.code != '' && question.code != 'nationality'&& question.code != 'second_nationality' && question.code != 'curp'" v-model="question.answer" type="text" :name="question.code" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <input v-else-if="(question.code == 'curp' && nationality == 'México')" v-model="question.answer" type="text" :name="question.code" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <input v-else-if="question.type == 'text' && question.code == ''" v-model="question.answer" type="text" :name="'question_'+question.id" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <!-- Number -->
                    <input v-if="question.type == 'number' && question.code != ''" v-model="question.answer" type="number"  :name="question.code" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <input v-if="question.type == 'number' && question.code == ''" v-model="question.answer" type="number" :name="'question_'+question.id" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <!-- File -->
                    <CInput v-if="question.type == 'file' && question.code != ''" v-model="question.answer" type="file" id="formFile" :name="question.code" label="" label="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                    <CInput v-if="question.type == 'file' && question.code == ''" v-model="question.answer" type="file" id="formFile" :name="'question_'+question.id" label="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                    <!-- Options -->
                    <div style="margin-bottom: 10px;" v-if="question.type == 'options'" v-for="option in question.options">
                      <input class="form-check-input" v-if="question.code != ''" type="radio" v-model="question.answer" :value="option" id="flexRadioDefault2" style="margin-left: 10px;" @click="hasSecondaNationality(option)">
                      <input class="form-check-input" v-if="question.code == ''" type="radio" v-model="question.answer" :name="'question_'+question.id" :value="option" id="flexRadioDefault2" style="margin-left: 10px;" @click="hasSecondaNationality(option)">
                      <span style="background-color: #CCC; border-radius: 5px; padding: 5px; font-size: 12px; min-width: 18px; height: 18px;font-size: 13px; margin-left: 30px; margin-right: 40px;">{{option}}</span>
                    </div>
                    <!-- Date -->
                    <CRow v-if="question.type == 'date' && question.code == 'birthdate'">
                      <CCol md="8" style="text-align: left;">
                        <div style="padding: 0px 0 0 0px; font-size: 16px; font-family: 'Open Sans'; ">
                          <v-date-picker
                            class="date-picker"
                            v-model="form.birthdate"
                            locale="es-MX"
                            size="sm"
                            style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                          />
                          <span v-if="question.error && question.code == 'birthdate'" style="color: red; font-size: 12px; text-align: right;">{{question.error}}</span>
                        </div>
                      </CCol>
                      <CCol md="4" style="text-align: left;">
                        <div style="padding: 0px 0 0 0px; font-size: 16px; font-family: 'Open Sans'; ">
                          <input type="text" v-model="form.age" readonly style="font-size: 12px; text-align: center; padding: 8px; width: 100%; border-radius: 0.4rem; border-color: rgb(240,240,240);">
                        </div>
                      </CCol>
                    </CRow>
                    <CRow v-if="question.type == 'date' && question.code != 'birthdate'">
                      <CCol md="12" style="text-align: left;">
                        <div style="position: relative; top: -25px; padding: 30px 0 0 0px; font-size: 16px; font-family: 'Open Sans'; ">
                          <v-date-picker
                            v-if="question.code != '' && question.code != null"
                            class="date-picker"
                            v-model="question.answer"
                            locale="es-MX"
                            size="sm"
                            style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                          />
                          <v-date-picker
                            v-if="question.code == '' || question.code == null"
                            :name="'question_'+question.id"
                            class="date-picker"
                            v-model="question.answer"
                            locale="es-MX"
                            size="sm"
                            style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                          />
                        </div>
                      </CCol>
                    </CRow>
                    <!-- Validation -->
                    <span v-if="question.error && (question.code != 'birthdate' && (question.code != 'curp' || (question.code == 'curp' && nationality == 'México')))" style="color: red; font-size: 12px; text-align: right;">{{question.error}}</span>
                  </div>
                </CCol>
              </CRow>
              <!-- Termina Preguntas Genéricas -->
              <!-- Correo Electrónico -->
              <CRow id="customer_email" ref="customer_email">
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -20px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>Correo de la madre, padre o tutor</b> <span style="color: red">*</span>
                    <br />
                    <span style="font-size: 14px;">Información muy importante para enviar confirmación de inscripción al viaje y pagos, favor de escribirlo correctamente.</span>
                    <br />
                    <br />
                    <input type="text" v-model="form.customer_email" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <span v-if="errors.customer_email" style="color: red; font-size: 12px; text-align: right;">{{errors.customer_email}}</span>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <CRow id="customer_email2" ref="customer_email2">
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -50px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>Confirmación de correo de la madre, padre o tutor</b> <span style="color: red">*</span>
                    <br />
                    <span style="font-size: 14px;">Es importante validar el correo para garantizar que lo hayas escrito correctamente, ya que será la via de acceso a la plataforma.</span>
                    <br />
                    <br />
                    <input type="text" v-model="form.customer_email2" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <span v-if="errors.customer_email2" style="color: red; font-size: 12px; text-align: right;">{{errors.customer_email2}}</span>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <!-- Termina Correo Electrónico -->
              <!-- Preguntas Dinámicas -->
              <CRow v-for="question in travel.questions">
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -50px; padding: 30px 30px 0 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>{{question.question}}</b> <span v-if="question.required" style="color: red">*</span>
                    <br />
                    <span style="font-size: 14px;" v-if="question.description">{{question.description}}</span>
                    <br v-if="question.description" />
                    <br />
                    <input v-if="question.type == 'text'" v-model="question.answer" type="text" :name="'text_'+question.id" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <div style="margin-bottom: 10px;" v-if="question.type == 'options'" v-for="option in question.options">
                      <input class="form-check-input" type="radio" v-model="question.answer" :value="option" :name="'options_'+question.id" id="flexRadioDefault2" style="margin-left: 10px;">
                      <span style="background-color: #CCC; border-radius: 5px; padding: 5px; font-size: 12px; min-width: 18px; height: 18px;font-size: 13px; margin-left: 30px; margin-right: 40px;">{{option}}</span>
                    </div>
                    <span v-if="question.error" style="color: red; font-size: 12px; text-align: right;">{{question.error}}</span>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <!-- Termina Preguntas Dinámicas -->
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -50px; padding: 30px 30px 0 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>Observaciones o comentarios</b>
                    <br />
                    <span style="font-size: 14px;">Si hay algo importante que quieras comentarnos sobre el alumno(a), por favor escríbelo aquí.</span>
                    <br />
                    <br />
                    <CTextarea v-model="form.comments" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      text="Must be 8-20 words long."
                    ></CTextarea>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -50px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>Términos y condiciones</b> <span style="color: red">*</span>
                    <br /><br />
                    <div style="margin-bottom: 10px;">
                      <input class="form-check-input" type="checkbox" v-model="form.accept" name="accept" id="flexRadioDefault2" style="margin-left: 0px;">
                      <span style="font-size: 14px; margin-left: 25px;">Acepto que la información anterior es fidedigna y confiable. Los datos aquí proporcionados serán utilizados por Con Equis para la correcta realización del viaje.</span>
                      <br />
                      <span v-if="errors.terms" style="color: red; font-size: 12px; text-align: right;">{{errors.terms}}</span>
                    </div>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -80px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; text-align: left;">
                    <b>Antes de firmar, revisa el contrato y la carta de responsabilidad. Si estás de acuerdo, firmalos de manera digital.</b>
                    <br /><br />
                    <ul>
                      <li><a href="https://conequis.com.mx/contrato2024.pdf" target="_blank">Contrato</a></li>
                      <li v-if="travel.id != 7"><a href="https://conequis.com.mx/responsiva_general2024.pdf" target="_blank">Carta Responsiva</a></li>
                      <li v-if="travel.id == 7"><a href="https://conequis.com.mx/responsiva_tapalpa2024.pdf" target="_blank">Carta Responsiva</a></li>
                    </ul>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -120px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <div style="margin-bottom: 10px;">
                      <input class="form-check-input" type="checkbox" v-model="form.contrato" name="contrato" id="flexRadioDefault2" style="margin-left: 0px;">
                      <span style="font-size: 14px; margin-left: 25px;">He leído el <b>contrato</b> y acepto todos lo puntos que ahí se desglosan.</span>
                      <br />
                      <span v-if="errors.contrato" style="color: red; font-size: 12px; text-align: right;">{{errors.contrato}}</span>
                    </div>
                    <br />
                    <div style="margin-bottom: 10px;">
                      <input class="form-check-input" type="checkbox" v-model="form.responsiva" name="responsiva" id="flexRadioDefault2" style="margin-left: 0px;">
                      <span style="font-size: 14px; margin-left: 25px;">He leído la <b>carta responsiva</b> y acepto todos lo puntos que ahí se desglosan.</span>
                      <br />
                      <span v-if="errors.responsiva" style="color: red; font-size: 12px; text-align: right;">{{errors.responsiva}}</span>
                    </div>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -140px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; text-align: center;">
                    <CButton v-if="!is_loading" @click="sendData" color="info">Enviar y Firmar Contrato</CButton>
                    <div v-if="is_loading"><loading :active="is_loading" :can-cancel="true"></loading>Enviando Información</div>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
            </CContainer>
          </CForm>
        </main>
      </div>
    </CWrapper>
    <SignModal ref="signModal" @sign="sign"></SignModal>
  </div>
</template>

<script>
import store from '../../store'
import travels from '../../services/travels';
import schools from '../../services/schools';
import enrollments from '../../services/enrollments';
import SignModal from '../../components/enrollments/SignModal.vue'
import { DatePicker } from 'v-calendar'
import Loading from 'vue-loading-overlay/dist/vue-loading.js';

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'EnrollmentForm',
  components: { SignModal, 'v-date-picker': DatePicker, Loading },
  data: function () {
		return {
      enrollment_id: 0,
      enrollment: 0,
      is_loading: false,
      code: '',
      header_type: '',
      travel: false,
      schools: [],
      nationalities: [
          {
            "name": "Afghanistan",
            "es_name": "Afganistán"
          },
          {
            "name": "Albania",
            "es_name": "Albania"
          },
          {
            "name": "Algeria",
            "es_name": "Algeria"
          },
          {
            "name": "American Samoa",
            "es_name": "Samoa Americana"
          },
          {
            "name": "Andorra",
            "es_name": "Andorra"
          },
          {
            "name": "Angola",
            "es_name": "Angola"
          },
          {
            "name": "Anguilla",
            "es_name": "Anguilla"
          },
          {
            "name": "Antarctica",
            "es_name": "Antártida"
          },
          {
            "name": "Antigua and Barbuda",
            "es_name": "Antigua y Barbuda"
          },
          {
            "name": "Argentina",
            "es_name": "Argentina"
          },
          {
            "name": "Armenia",
            "es_name": "Armenia"
          },
          {
            "name": "Aruba",
            "es_name": "Aruba"
          },
          {
            "name": "Australia",
            "es_name": "Australia"
          },
          {
            "name": "Austria",
            "es_name": "Austria"
          },
          {
            "name": "Azerbaijan",
            "es_name": "Azerbaiyán"
          },
          {
            "name": "Bahamas",
            "es_name": "Bahamas"
          },
          {
            "name": "Bahrain",
            "es_name": "Bahrein"
          },
          {
            "name": "Bangladesh",
            "es_name": "Bangladesh"
          },
          {
            "name": "Barbados",
            "es_name": "Barbados"
          },
          {
            "name": "Belarus",
            "es_name": "Bielorrusia"
          },
          {
            "name": "Belgium",
            "es_name": "Bélgica"
          },
          {
            "name": "Belize",
            "es_name": "Belice"
          },
          {
            "name": "Benin",
            "es_name": "Benín"
          },
          {
            "name": "Bermuda",
            "es_name": "Bermuda"
          },
          {
            "name": "Bhutan",
            "es_name": "Bután"
          },
          {
            "name": "Bolivia",
            "es_name": "Bolivia"
          },
          {
            "name": "Bosnia and Herzegovina",
            "es_name": "Bosnia-Herzegovina"
          },
          {
            "name": "Botswana",
            "es_name": "Botswana"
          },
          {
            "name": "Brazil",
            "es_name": "Brasil"
          },
          {
            "name": "Brunei Darussalam",
            "es_name": "Brunei"
          },
          {
            "name": "Bulgaria",
            "es_name": "Bulgaria"
          },
          {
            "name": "Burkina Faso",
            "es_name": "Burkina Faso"
          },
          {
            "name": "Burundi",
            "es_name": "Burundi"
          },
          {
            "name": "Cambodia",
            "es_name": "Camboya"
          },
          {
            "name": "Cameroon",
            "es_name": "Camerún"
          },
          {
            "name": "Canada",
            "es_name": "Canadá"
          },
          {
            "name": "Cape Verde",
            "es_name": "Cabo Verde"
          },
          {
            "name": "Cayman Islands",
            "es_name": "Islas Caimán"
          },
          {
            "name": "Central African Republic",
            "es_name": "República Centroafricana"
          },
          {
            "name": "Chad",
            "es_name": "Chad"
          },
          {
            "name": "Chile",
            "es_name": "Chile"
          },
          {
            "name": "China",
            "es_name": "China"
          },
          {
            "name": "Christmas Island",
            "es_name": "Isla de Navidad"
          },
          {
            "name": "Cocos (Keeling) Islands",
            "es_name": "Islas Cocos"
          },
          {
            "name": "Colombia",
            "es_name": "Colombia"
          },
          {
            "name": "Comoros",
            "es_name": "Comores"
          },
          {
            "name": "Congo",
            "es_name": "República del Congo"
          },
          {
            "name": "Congo, The Democratic Republic of the",
            "es_name": "República Democrática del Congo"
          },
          {
            "name": "Cook Islands",
            "es_name": "Islas Cook"
          },
          {
            "name": "Costa Rica",
            "es_name": "Costa Rica"
          },
          {
            "name": "Cote D'Ivoire",
            "es_name": "Costa de Marfíl"
          },
          {
            "name": "Croatia",
            "es_name": "Croacia"
          },
          {
            "name": "Cuba",
            "es_name": "Cuba"
          },
          {
            "name": "Cyprus",
            "es_name": "Chipre"
          },
          {
            "name": "Czech Republic",
            "es_name": "República Checa"
          },
          {
            "name": "Denmark",
            "es_name": "Dinamarca"
          },
          {
            "name": "Djibouti",
            "es_name": "Djibouti"
          },
          {
            "name": "Dominica",
            "es_name": "Dominica"
          },
          {
            "name": "Dominican Republic",
            "es_name": "República Dominicana"
          },
          {
            "name": "Ecuador",
            "es_name": "Ecuador"
          },
          {
            "name": "Egypt",
            "es_name": "Egipto"
          },
          {
            "name": "El Salvador",
            "es_name": "El Salvador"
          },
          {
            "name": "Equatorial Guinea",
            "es_name": "Guinea Ecuatorial"
          },
          {
            "name": "Eritrea",
            "es_name": "Eritrea"
          },
          {
            "name": "Estonia",
            "es_name": "Estonia"
          },
          {
            "name": "Ethiopia",
            "es_name": "Etiopía"
          },
          {
            "name": "Falkland Islands (Malvinas)",
            "es_name": "Islas Malvinas"
          },
          {
            "name": "Faroe Islands",
            "es_name": "Islas Feroe"
          },
          {
            "name": "Fiji",
            "es_name": "Fiji"
          },
          {
            "name": "Finland",
            "es_name": "Finlandia"
          },
          {
            "name": "France",
            "es_name": "Francia"
          },
          {
            "name": "French Guiana",
            "es_name": "Guyana Francesa"
          },
          {
            "name": "French Polynesia",
            "es_name": "Polinesia Francesa"
          },
          {
            "name": "French Southern Territories",
            "es_name": "Tierras Australes y Antárticas Francesas"
          },
          {
            "name": "Gabon",
            "es_name": "Gabón"
          },
          {
            "name": "Gambia",
            "es_name": "Gambia"
          },
          {
            "name": "Georgia",
            "es_name": "Georgia"
          },
          {
            "name": "Germany",
            "es_name": "Alemania"
          },
          {
            "name": "Ghana",
            "es_name": "Ghana"
          },
          {
            "name": "Gibraltar",
            "es_name": "Gibraltar"
          },
          {
            "name": "Greece",
            "es_name": "Grecia"
          },
          {
            "name": "Greenland",
            "es_name": "Groenlandia"
          },
          {
            "name": "Grenada",
            "es_name": "Granada"
          },
          {
            "name": "Guadeloupe",
            "es_name": "Guadalupe"
          },
          {
            "name": "Guam",
            "es_name": "Guam"
          },
          {
            "name": "Guatemala",
            "es_name": "Guatemala"
          },
          {
            "name": "Guinea",
            "es_name": "Guinea"
          },
          {
            "name": "Guinea-Bissau",
            "es_name": "Guinea-Bissau"
          },
          {
            "name": "Guyana",
            "es_name": "Guyana"
          },
          {
            "name": "Haiti",
            "es_name": "Haití"
          },
          {
            "name": "Holy See (Vatican City State)",
            "es_name": "Vaticano"
          },
          {
            "name": "Honduras",
            "es_name": "Honduras"
          },
          {
            "name": "Hong Kong",
            "es_name": "Hong Kong"
          },
          {
            "name": "Hungary",
            "es_name": "Hungría"
          },
          {
            "name": "Iceland",
            "es_name": "Islandia"
          },
          {
            "name": "India",
            "es_name": "India"
          },
          {
            "name": "Indonesia",
            "es_name": "Indonesia"
          },
          {
            "name": "Iran, Islamic Republic Of",
            "es_name": "Irán"
          },
          {
            "name": "Iraq",
            "es_name": "Iraq"
          },
          {
            "name": "Ireland",
            "es_name": "Irlanda"
          },
          {
            "name": "Israel",
            "es_name": "Israel"
          },
          {
            "name": "Italy",
            "es_name": "Italia"
          },
          {
            "name": "Jamaica",
            "es_name": "Jamaica"
          },
          {
            "name": "Japan",
            "es_name": "Japón"
          },
          {
            "name": "Jordan",
            "es_name": "Jordania"
          },
          {
            "name": "Kazakhstan",
            "es_name": "Kazajstán"
          },
          {
            "name": "Kenya",
            "es_name": "Kenia"
          },
          {
            "name": "Kiribati",
            "es_name": "Kiribati"
          },
          {
            "name": "Korea, Democratic People'S Republic of",
            "es_name": "Corea del Norte"
          },
          {
            "name": "Korea, Republic of",
            "es_name": "Corea del Sur"
          },
          {
            "name": "Kuwait",
            "es_name": "Kuwait"
          },
          {
            "name": "Kyrgyzstan",
            "es_name": "Kirguistán"
          },
          {
            "name": "Lao People's Democratic Republic",
            "es_name": "Laos"
          },
          {
            "name": "Latvia",
            "es_name": "Letonia"
          },
          {
            "name": "Lebanon",
            "es_name": "Líbano"
          },
          {
            "name": "Lesotho",
            "es_name": "Lesotho"
          },
          {
            "name": "Liberia",
            "es_name": "Liberia"
          },
          {
            "name": "Libyan Arab Jamahiriya",
            "es_name": "Libia"
          },
          {
            "name": "Liechtenstein",
            "es_name": "Liechtenstein"
          },
          {
            "name": "Lithuania",
            "es_name": "Lituania"
          },
          {
            "name": "Luxembourg",
            "es_name": "Luxemburgo"
          },
          {
            "name": "Macao",
            "es_name": "Macao"
          },
          {
            "name": "Macedonia, The Former Yugoslav Republic of",
            "es_name": "Macedonia"
          },
          {
            "name": "Madagascar",
            "es_name": "Madagascar"
          },
          {
            "name": "Malawi",
            "es_name": "Malawi"
          },
          {
            "name": "Malaysia",
            "es_name": "Malasia"
          },
          {
            "name": "Maldives",
            "es_name": "Maldivas"
          },
          {
            "name": "Mali",
            "es_name": "Mali"
          },
          {
            "name": "Malta",
            "es_name": "Malta"
          },
          {
            "name": "Marshall Islands",
            "es_name": "Islas Marshall"
          },
          {
            "name": "Martinique",
            "es_name": "Martinica"
          },
          {
            "name": "Mauritania",
            "es_name": "Mauritania"
          },
          {
            "name": "Mauritius",
            "es_name": "Mauricio"
          },
          {
            "name": "Mayotte",
            "es_name": "Mayotte"
          },
          {
            "name": "Mexico",
            "es_name": "México"
          },
          {
            "name": "Micronesia, Federated States of",
            "es_name": "Estados Federados de Micronesia"
          },
          {
            "name": "Moldova, Republic of",
            "es_name": "Moldavia"
          },
          {
            "name": "Monaco",
            "es_name": "Mónaco"
          },
          {
            "name": "Mongolia",
            "es_name": "Mongolia"
          },
          {
            "name": "Montserrat",
            "es_name": "Montserrat"
          },
          {
            "name": "Morocco",
            "es_name": "Marruecos"
          },
          {
            "name": "Mozambique",
            "es_name": "Mozambique"
          },
          {
            "name": "Myanmar",
            "es_name": "Myanmar"
          },
          {
            "name": "Namibia",
            "es_name": "Namibia"
          },
          {
            "name": "Nauru",
            "es_name": "Nauru"
          },
          {
            "name": "Nepal",
            "es_name": "Nepal"
          },
          {
            "name": "Netherlands",
            "es_name": "Holanda"
          },
          {
            "name": "Netherlands Antilles",
            "es_name": "Antillas Holandesas"
          },
          {
            "name": "New Caledonia",
            "es_name": "Nueva Caledonia"
          },
          {
            "name": "New Zealand",
            "es_name": "Nueva Zelanda"
          },
          {
            "name": "Nicaragua",
            "es_name": "Nicaragua"
          },
          {
            "name": "Niger",
            "es_name": "Niger"
          },
          {
            "name": "Nigeria",
            "es_name": "Nigeria"
          },
          {
            "name": "Niue",
            "es_name": "Niue"
          },
          {
            "name": "Norfolk Island",
            "es_name": "Islas Norfolk"
          },
          {
            "name": "Northern Mariana Islands",
            "es_name": "Islas Marianas del Norte"
          },
          {
            "name": "Norway",
            "es_name": "Noruega"
          },
          {
            "name": "Oman",
            "es_name": "Omán"
          },
          {
            "name": "Pakistan",
            "es_name": "Pakistán"
          },
          {
            "name": "Palau",
            "es_name": "Palau"
          },
          {
            "name": "Palestinian Territory, Occupied",
            "es_name": "Palestina"
          },
          {
            "name": "Panama",
            "es_name": "Panamá"
          },
          {
            "name": "Papua New Guinea",
            "es_name": "Papua Nueva Guinea"
          },
          {
            "name": "Paraguay",
            "es_name": "Paraguay"
          },
          {
            "name": "Peru",
            "es_name": "Perú"
          },
          {
            "name": "Philippines",
            "es_name": "Filipinas"
          },
          {
            "name": "Pitcairn",
            "es_name": "Pitcairn"
          },
          {
            "name": "Poland",
            "es_name": "Polonia"
          },
          {
            "name": "Portugal",
            "es_name": "Portugal"
          },
          {
            "name": "Puerto Rico",
            "es_name": "Puerto Rico"
          },
          {
            "name": "Qatar",
            "es_name": "Qatar"
          },
          {
            "name": "Reunion",
            "es_name": "Reunión"
          },
          {
            "name": "Romania",
            "es_name": "Rumanía"
          },
          {
            "name": "Russian Federation",
            "es_name": "Rusia"
          },
          {
            "name": "Rwanda",
            "es_name": "Ruanda"
          },
          {
            "name": "Saint Helena",
            "es_name": "Santa Helena"
          },
          {
            "name": "Saint Kitts and Nevis",
            "es_name": "San Kitts y Nevis"
          },
          {
            "name": "Saint Lucia",
            "es_name": "Santa Lucía"
          },
          {
            "name": "Saint Vincent and the Grenadines",
            "es_name": "San Vicente y Granadinas"
          },
          {
            "name": "Samoa",
            "es_name": "Samoa"
          },
          {
            "name": "San Marino",
            "es_name": "San Marino"
          },
          {
            "name": "Sao Tome and Principe",
            "es_name": "Santo Tomé y Príncipe"
          },
          {
            "name": "Saudi Arabia",
            "es_name": "Arabia Saudita"
          },
          {
            "name": "Senegal",
            "es_name": "Senegal"
          },
          {
            "name": "Serbia and Montenegro",
            "es_name": "Serbia"
          },
          {
            "name": "Seychelles",
            "es_name": "Seychelles"
          },
          {
            "name": "Sierra Leone",
            "es_name": "Sierra Leona"
          },
          {
            "name": "Singapore",
            "es_name": "Singapur"
          },
          {
            "name": "Slovakia",
            "es_name": "Eslovaquía"
          },
          {
            "name": "Slovenia",
            "es_name": "Eslovenia"
          },
          {
            "name": "Solomon Islands",
            "es_name": "Islas Salomón"
          },
          {
            "name": "Somalia",
            "es_name": "Somalia"
          },
          {
            "name": "South Africa",
            "es_name": "Sudáfrica"
          },
          {
            "name": "Spain",
            "es_name": "España"
          },
          {
            "name": "Sri Lanka",
            "es_name": "Sri Lanka"
          },
          {
            "name": "Sudan",
            "es_name": "Sudán"
          },
          {
            "name": "Suriname",
            "es_name": "Surinam"
          },
          {
            "name": "Swaziland",
            "es_name": "Swazilandia"
          },
          {
            "name": "Sweden",
            "es_name": "Suecia"
          },
          {
            "name": "Switzerland",
            "es_name": "Suiza"
          },
          {
            "name": "Syrian Arab Republic",
            "es_name": "Siria"
          },
          {
            "name": "Taiwan, Province of China",
            "es_name": "Taiwán"
          },
          {
            "name": "Tajikistan",
            "es_name": "Tadjikistan"
          },
          {
            "name": "Tanzania, United Republic of",
            "es_name": "Tanzania"
          },
          {
            "name": "Thailand",
            "es_name": "Tailandia"
          },
          {
            "name": "Timor-Leste",
            "es_name": "Timor Oriental"
          },
          {
            "name": "Togo",
            "es_name": "Togo"
          },
          {
            "name": "Tokelau",
            "es_name": "Tokelau"
          },
          {
            "name": "Tonga",
            "es_name": "Tonga"
          },
          {
            "name": "Trinidad and Tobago",
            "es_name": "Trinidad y Tobago"
          },
          {
            "name": "Tunisia",
            "es_name": "Túnez"
          },
          {
            "name": "Turkey",
            "es_name": "Turquía"
          },
          {
            "name": "Turkmenistan",
            "es_name": "Turkmenistan"
          },
          {
            "name": "Turks and Caicos Islands",
            "es_name": "Islas Turcas y Caicos"
          },
          {
            "name": "Tuvalu",
            "es_name": "Tuvalu"
          },
          {
            "name": "Uganda",
            "es_name": "Uganda"
          },
          {
            "name": "Ukraine",
            "es_name": "Ucrania"
          },
          {
            "name": "United Arab Emirates",
            "es_name": "Emiratos Árabes Unidos"
          },
          {
            "name": "United Kingdom",
            "es_name": "Reino Unido"
          },
          {
            "name": "United States",
            "es_name": "Estados Unidos"
          },
          {
            "name": "Uruguay",
            "es_name": "Uruguay"
          },
          {
            "name": "Uzbekistan",
            "es_name": "Uzbekistán"
          },
          {
            "name": "Vanuatu",
            "es_name": "Vanuatu"
          },
          {
            "name": "Venezuela",
            "es_name": "Venezuela"
          },
          {
            "name": "Viet Nam",
            "es_name": "Vietnam"
          },
          {
            "name": "Virgin Islands, British",
            "es_name": "Islas Vírgenes Británicas"
          },
          {
            "name": "Virgin Islands, U.S.",
            "es_name": "Islas Vírgenes Americanas"
          },
          {
            "name": "Wallis and Futuna",
            "es_name": "Wallis y Futuna"
          },
          {
            "name": "Western Sahara",
            "es_name": "Sáhara Occidental"
          },
          {
            "name": "Yemen",
            "es_name": "Yemen"
          },
          {
            "name": "Zambia",
            "es_name": "Zambia"
          },
          {
            "name": "Zimbabwe",
            "es_name": "Zimbabwe"
          }
      ],
      nationality: 'México',
      second_nationality: '',
      form: {
        travel_id: 0,
        name: '',
        first_name: '',
        last_name: '',
        mother_last_name: '',
        birthdate: '',
        age: '',
        nationality: '',
        residence: '',
        has_second_nationality: "",
        second_nationality: '',
        out_passport: '',
        in_passport: '',
        passport: '',
        visa: '',
        curp: '',
        gender: '',
        height: '',
        diseases: '',
        allergies: '',
        customer_name: '',
        customer_email: '',
        customer_email2: '',
        customer_phone: '',
        emergency_phone: '',
        comments: '',
        accept: false,
        contrato: false,
        responsiva: false
      },
      has_errors: false,
      first_error: false,
      errors: {
        name: '',
        first_name: '',
        last_name: '',
        mother_last_name: '',
        birthdate: '',
        age: '',
        nationality: 'México',
        residence: '',
        has_second_nationality: "",
        second_nationality: '',
        out_passport: '',
        in_passport: '',
        passport: '',
        visa: '',
        curp: '',
        gender: '',
        height: '',
        diseases: '',
        allergies: '',
        customer_name: '',
        customer_email: '',
        customer_email2: '',
        customer_phone: '',
        emergency_phone: '',
        comments: '',
        terms: ''
      }
    }
  },
  mounted: async function() {
    this.code = await this.$route.params.code;

    let response = await travels.show(this.code);

    if(response.type == "success"){
      this.travel = response.data;

      if(response.data.is_active == 0){
        window.location = "/#/login";

        return;
      }

      this.form.travel_id = this.travel.id;
      this.form.school_id = this.travel.school_id;

      this.travel.form_questions.forEach(question => {
        if(question.code == "nationality"){
          question.answer = "México";
        }
      });

      this.header_type = 'background-image: url("'+this.travel.image_filename+'"); overflow: hidden; background-position: center; width: 100%; height: 240px; background-repeat: no-repeat;background-size: cover;';
    }
  },
  methods: {
    changeNationality(event, question){
      this.nationality = event.target.value;
    },
    hasSecondaNationality(event, question){
      this.second_nationality = event == "SI" ? true : false;
    },
    async sendData (){
      if(this.validateData() && this.validateTerms()){
        if(!this.has_errors){
          this.$refs.signModal.openModal();
        }
      }
    },
    async sign (sign){
      this.loading();

      const form = document.getElementById("enrollment_form");
      const formData = new FormData(form); 

      this.travel.form_questions.forEach(question => {
        if(question.code != "" && question.code != null){
            formData.append(question.code, question.answer && question.answer != undefined ? question.answer : '');
          }
          else{
            if(question.type == "date"){
              let date = new moment(question.answer);
              formData.append("question_"+question.id, date.format("YYYY-MM-DD"));
            }
            else{
              formData.append("question_"+question.id, question.answer && question.answer != undefined ? question.answer : '');
            }
          }
      });

      formData.append("travel_id", this.form.travel_id);
      formData.append("school_id", this.form.school_id);
      formData.append("customer_email", this.form.customer_email);
      formData.append("comments", this.form.comments);

      if(this.form.birthdate){
        let birthdate = new moment(this.form.birthdate);
        formData.append("birthdate", birthdate.format("YYYY-MM-DD"));
        formData.append("age", this.form.age);
      }

      //Agregando al formulario los campos personalizados
      this.travel.questions.forEach(question => {
        formData.append("question_"+question.id, question.answer && question.answer != undefined ? question.answer : '');
      });

      formData.append("sign", sign);

      let response = await enrollments.store(formData);

      if(response.type == "success"){
        let data = response.data;

        this.enrollment_id = data.id;
        this.enrollment = data;
      }
      else{
        this.showToast(response.type, response.message);
      }

      this.loaded();
    },
    validateData (){
      this.has_errors = false;
      this.first_error = false;
      
      this.errors = {
        name: '',
        first_name: '',
        last_name: '',
        mother_last_name: '',
        birthdate: '',
        age: '',
        nationality: '',
        residence: '',
        has_second_nationality: "",
        second_nationality: '',
        out_passport: '',
        in_passport: '',
        passport: '',
        visa: '',
        curp: '',
        gender: '',
        height: '',
        diseases: '',
        allergies: '',
        customer_name: '',
        customer_email: '',
        customer_email2: '',
        customer_phone: '',
        emergency_phone: '',
        comments: '',
        terms: ''
      }

      let _this = this;

      //Validación de campos personalizados
      let n = 0;
      this.travel.form_questions.forEach(question => {
        question.error = "";

        if(question.required == 1){
          if((question.type == "date" && question.answer !== undefined && question.code != "birthdate")){
            if(question.answer !== undefined && question.answer != ''){
              return;
            }
            question.error = "La fecha es obligatoria."
          }

          if(question.code == "curp" && _this.nationality !== 'México'){
            return;
          }

          if(question.code == "curp" && _this.nationality == 'México' && question.answer != '' && question.answer != undefined){
            var regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
            let curp = question.answer;
            if(!curp.match(regex)){
              question.error = "La CURP del alumno(a) no cuenta con el formato correcto.";
              _this.has_errors = true;
              _this.first_error = _this.first_error ? _this.first_error : question.code;
              return;
            }
          }

          if((question.code == "birthdate" && _this.form.birthdate !== undefined) || (question.code != "birthdate" && question.answer === undefined || question.answer.trim() === '')){
            if(question.code == "birthdate"){
              if(_this.form.birthdate !== undefined && _this.form.birthdate != ''){
                return;
              }
              question.error = "La fecha de nacimiento del alumno(a) es obligatoria."
            }

            question.error = "El campo es obligatorio."

            _this.has_errors = true;
            _this.first_error = _this.first_error ? _this.first_error : question.code;

            if(question.code == "first_name"){ question.error = "El nombre del alumno(a) es obligatorio." }
            if(question.code == "last_name"){ question.error = "El apellido paterno del alumno(a) es obligatorio." }
            if(question.code == "mother_last_name"){ question.error = "El apellido materno del alumno(a) es obligatorio." }
            if(question.code == "birthdate"){ question.error = "La fecha de nacimiento del alumno(a) es obligatoria." }
            if(question.code == "height"){ question.error = "La estatura del alumno(a) es obligatoria." }
            if(question.code == "gender"){ question.error = "El género del alumno(a) es obligatorio." }
            if(question.code == "nationality"){ question.error = "La nacionalidad del alumno(a) es obligatoria." }
            if(question.code == "curp"){ question.error = "La CURP del alumno(a) es obligatoria." }
            if(question.code == "residence"){ question.error = "La residencia del alumno(a) es obligatoria." }
            if(question.code == "has_second_nationality"){ question.error = "Se debe indicar si el alumno(a) cuenta con una segunda nacionalidad." }
            if(question.code == "out_passport"){ question.error = "Se debe indicar cual es el pasaporte con el que sale de México el alumno(a)." }
            if(question.code == "in_passport"){ question.error = "Se debe indicar cual es el pasaporte con el que ingresa al país destino el alumno(a)." }
            if(question.code == "passport"){ question.error = "El pasaporte del alumno(a) es obligatorio." }
            if(question.code == "visa"){ question.error = "La visa estadounidense del alumno(a) es obligatoria." }
            if(question.code == "customer_name"){ question.error = "El nombre del padre, madre o tutor del alumno(a) es obligatorio." }
            if(question.code == "customer_email"){ question.error = "El correo del padre, madre o tutor del alumno(a) es obligatorio." }
            if(question.code == "customer_phone"){ question.error = "El télefono del padre, madre o tutor del alumno(a) es obligatorio." }
            if(question.code == "emergency_phone"){ question.error = "El teléfono de emergencia para el alumno(a) es obligatorio." }

            return;
          }
        }

        n++;
      });

      if(this.form.customer_email.trim() == ''){
        this.errors.customer_email = "El correo de la madre, padre o tutor del alumno(a) es obligatorio."
        this.has_errors = true;
        this.first_error = this.first_error ? this.first_error : 'customer_email';
      }
      else{
        var validEmail =  /^\w+([._+$-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

        if(!validEmail.test(this.form.customer_email.trim())){
          this.errors.customer_email = "El acorreo no tiene un formato válido."
          this.has_errors = true;
          this.first_error = this.first_error ? this.first_error : 'customer_email';
        }
      }

      if(this.form.customer_email.trim() != this.form.customer_email2.trim()){
        this.errors.customer_email2 = "La confirmación y el correo electrónico no coinciden."
          this.has_errors = true;
          this.first_error = this.first_error ? this.first_error : 'customer_email2';
      }

      //Validación de campos personalizados
      let i = 0;
      this.travel.questions.forEach(question => {
        question.error = "";

        if(question.required == 1){
          if(question.answer === undefined || question.answer.trim() === ''){
            question.error = "El campo "+question.question+" del alumno(a) es obligatorio."
            this.has_errors = true;
            this.first_error = this.first_error ? this.first_error : question.code;
            return;
          }
        }

        i++;
      });

      if(this.has_errors == true){
        this.goto(this.first_error);

        return false;
      }

      return true;
    },
    validateTerms (){
      if(this.form.accept == ''){
        this.errors.terms = "Para continuar es necesario que aceptes los terminos y condiciones.";
        return false;
      }

      if(this.form.contrato == ''){
        this.errors.contrato = "Para continuar es necesario que leas y aceptes los puntos desglosados en el contrato.";
        return false;
      }

      if(this.form.responsiva == ''){
        this.errors.responsiva = "Para continuar es necesario que leas y aceptes los puntos desglosados en la carta responsiva.";
        return false;
      }

      return true;
    },
    goto(refName) {
      if(refName != ''){
        const d = document.getElementById(refName);
        const top = d.offsetTop - 50;
        
        window.scrollTo(0, top); 
      }
    },
    changeDate(event, id) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      this.is_loading = true;
    },
    loaded(){
      this.is_loading = false;
    }
  },
  watch: {
    'form.birthdate': {
      handler: function() {
        let date1 = new moment(this.travel.start_date); 
        let date2 = new moment(this.form.birthdate);

        let years = parseInt(date1.diff(date2, 'months') / 12);
        let months = date1.diff(date2, 'months') % 12;

        this.form.age = years+(years == 1 ? " año " : " años ")+months+(months == 1 ? " mes" : " meses");
      },
      deep: true
    }
  }
}
</script>
